@import 'libs/assets/theme/coreVariablesV2.scss';

.articleLable {
  height: 1.25rem;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  padding: 0 0.125rem 0 0;
  object-fit: contain;
  border-radius: 0.125rem;
  border: 0.0625rem solid transparent;
}
.labelRoot {
  height: 1.25rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-self: center;
  align-items: center;
  width: fit-content;
}
.iconContainer {
  height: 1.25rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  height: 1rem;
  width: 1rem;
  color: $white;
}
.background {
  &.red {
    background-color: $red100pct;
  }
  &.green {
    background-color: $green;
  }
  &.orange {
    background-color: $orange;
  }
  &.gray {
    background-color: $darkGray;
  }
  &.darkGray55K {
    background-color: $darkGray55K;
  }
}
.border {
  &.red {
    border-color: $red100pct;
  }
  &.green {
    border-color: $green;
  }
  &.orange {
    border-color: $orange;
  }
  &.gray {
    border-color: $darkGray;
  }
  &.darkGray55K {
    border-color: $darkGray55K;
  }
}
.text {
  font-weight: bold;
  font-size: 0.75rem;
  white-space: nowrap;
  &.red {
    color: $red100pct;
  }
  &.green {
    color: $green;
  }
  &.orange {
    color: $orange;
  }
  &.gray {
    color: $darkGray;
  }
  &.darkGray55K {
    color: $darkGray55K;
  }
}
